import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { childrenPropTypes } from '../utils/generic-prop-types';
import '../assets/css/loader.css';

const Loader = (props) => {
  const { className, children } = props;
  return (
    <div className={`loader${className ? ` ${className}` : ''}`}>
      <svg width="16px" height="12px">
        <polyline
          className="loader-back"
          points="1 6 4 6 6 11 10 1 12 6 15 6"
        />
        <polyline
          className="loader-front"
          points="1 6 4 6 6 11 10 1 12 6 15 6"
        />
      </svg>
      {children}
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes(),
};

Loader.defaultProps = {
  className: '',
  children: null,
};

export const CardLoader = (props) => {
  const { t } = useTranslation();
  const { bg, className } = props;
  return (
    <Loader className={`card-loader bg-${bg} ${className}`}>
      <span>
        {t('common:data-loading')}
      </span>
    </Loader>
  );
};

CardLoader.propTypes = {
  bg: PropTypes.string,
  className: PropTypes.string,
};

CardLoader.defaultProps = {
  bg: 'white-alpha',
  className: '',
};

export const InputLoader = () => (
  <Loader className="input-loader" />
);

export const MiniLoader = () => (
  <Loader className="mini-loader" />
);
