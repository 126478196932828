import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleProjectListTable from './SimpleProjectListTable';
import MobileView from './MobileView';
import { CardLoader } from './Loader';
import { nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import ComponentLifeTracker from '../utils/ComponentLifeTracker';
import { projectsActions, teamsActions } from '../redux/actions';


const mapStateToProps = (state) => ({
  user: state.auth.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: async (params, compLifeTracker) => dispatch(projectsActions.list(
    params, { pagination: 'short' }, () => compLifeTracker.isUnmounted(),
  )),
  clearProjects: () => dispatch(projectsActions.removeAllSuccess()),
  fetchTeams: async (userId, compLifeTracker) => dispatch(teamsActions.list(
    { members__user: userId },
    { pagination: 'no' },
    () => compLifeTracker.isUnmounted(),
  )),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class DashboardProjects extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    fetchProjects: PropTypes.func.isRequired,
    clearProjects: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      projects: [],
    };
    this.compLifeTracker = new ComponentLifeTracker();
  }

  componentDidMount() {
    this.compLifeTracker.setMounted();
    this.fetchData();
  }

  componentWillUnmount() {
    this.compLifeTracker.setUnmounted();
  }

  async fetchData() {
    this.setState({ loading: true });

    const {
      clearProjects, fetchProjects, fetchTeams, user,
    } = this.props;

    clearProjects();

    try {
      const res = await Promise.all([
        fetchProjects({ ordering: '-updated_at', is_disabled: 'false' }, this.compLifeTracker),
        fetchTeams(user.id, this.compLifeTracker),
      ]);

      if (this.compLifeTracker.isMounted()) {
        this.setState({ projects: res[0].results });
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      if (this.compLifeTracker.isMounted()) {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const { t } = this.props;
    const { loading, projects } = this.state;

    return (
      <div className="contains-loader">
        { loading && <CardLoader /> }
        <h4 className="font-weight-normal mb-3">
          {t('common:dashboard.your-recent-projects')}
        </h4>
        <SimpleProjectListTable
          {...this.props}
          projects={projects}
        />
        <MobileView>
          <div className="dashboard-view-more">
            <Link to="/dashboard/projects" className="text-newblue-1">
              {t('common:table.view-more')}
            </Link>
          </div>
        </MobileView>
      </div>
    );
  }
}

export default DashboardProjects;
