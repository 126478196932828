import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { childrenPropTypes } from '../utils/generic-prop-types';


const mapStateToProps = (state) => ({
  dragAndDrop: state.dragAndDrop,
});


@connect(mapStateToProps)
class DroppableComponent extends Component {
  static propTypes = {
    droppableId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    children: childrenPropTypes().isRequired,
    isDropDisabled: PropTypes.bool,
    dragAndDrop: PropTypes.shape().isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    isDropDisabled: false,
    className: '',
  };

  shouldComponentUpdate(nextProps) {
    return !nextProps.dragAndDrop.dragInProgress;
  }

  render() {
    const {
      droppableId, isDropDisabled, children, type, className,
    } = this.props;
    return (
      <Droppable
        droppableId={droppableId}
        type={type}
        isDropDisabled={isDropDisabled}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={className}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default DroppableComponent;
