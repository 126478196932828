import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import history from '../history';
import { nsOptions } from '../i18n';
import { isBrowserView } from './BrowserView';
import MixedView from './MixedView';
import MobileView from './MobileView';
import { TableCell, TableRow } from './CustomTable';
import { LabeledUserAvatar } from './avatar';
import { ProjectStatus, ProjectPaused, ProjectDeactivated } from './ProjectStatus';
import FormattedValue from '../utils/FormattedValue';
import TruncatedText from '../utils/TruncatedText';
import { PROJECT_CLOSED, PROJECT_CLOSED_WITH_MODIFICATIONS } from '../constants';


const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  teams: state.teams,
});


@connect(mapStateToProps)
@withTranslation('', nsOptions)
class ProjectListItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    project: PropTypes.shape(),
    admin: PropTypes.bool,
    teams: PropTypes.PropTypes.shape().isRequired,
  };

  static defaultProps = {
    project: null,
    admin: false,
  };

  isAdminProject = () => false;

  open = () => {
    const baseUrl = `${this.props.admin ? '/admin' : '/dashboard'}/project/${this.props.project.id}`;
    history.push(`${baseUrl}/${isBrowserView() ? 'details' : 'inclusions'}`);
  };

  getParticipantsCount = () => (
    `${Math.max(this.props.project.users.length - 1, 0) || '-'}` // Exclude manager from count
  );

  getProjectTeam = () => {
    const { project, teams } = this.props;
    if (!project.team || !Object.values(teams)) return '-';
    return teams[project.team] ? teams[project.team].name : '-';
  };

  getProjectPhase = () => {
    const { t, project } = this.props;

    if (project.is_frozen || project.is_disabled) {
      return <ProjectDeactivated onlyIcon />;
    }
    if (project.is_paused) {
      return <ProjectPaused onlyIcon />;
    }
    return (
      <ProjectStatus
        status={project.status}
        onlyIcon
        getTooltipContent={(status) => {
          const { closed_status_option: closedStatusOption } = project;
          return (
            <span>
              {t(`project:statuses.${status}`)}
              {status === PROJECT_CLOSED && (
                <>
                  {t('common:colon')}
                  <ul className="mb-0">
                    <li>
                      {t('project:closed-status-options.no-inclusion-creation')}
                    </li>
                    {closedStatusOption && (
                      <li>
                        {t(
                          'project:closed-status-options.modifications',
                          {
                            context: closedStatusOption === PROJECT_CLOSED_WITH_MODIFICATIONS
                              ? 'with' : 'without',
                          },
                        )}
                      </li>
                    )}
                  </ul>
                </>
              )}
            </span>
          );
        }}
      />
    );
  };

  render() {
    const {
      t, project, admin, user,
    } = this.props;

    const showTeam = admin || (user && user.limitations.can_own_licence_products);
    const showOwner = admin || (user && (user.limitations.can_join_project_as_external
      || user.limitations.can_join_project_in_team));

    const isDeactivated = project && (project.is_frozen || project.is_disabled);

    return project && project !== undefined ? [
      (
        <MixedView key={0}>
          <TableRow
            style={{ height: '65px' }}
            onClick={this.open}
            className={(this.isAdminProject() ? 'admin-project-item' : '', isDeactivated ? 'color-gray' : '')}
          >
            <TableCell
              className="align-middle text-center font-weight-semibold"
            >
              <TruncatedText
                text={project.name}
                maxLength={40}
              />
            </TableCell>
            {showTeam && (
              <TableCell>
                <TruncatedText
                  text={this.getProjectTeam()}
                  maxLength={40}
                />
              </TableCell>
            )}
            {showOwner && (
              <TableCell>
                <LabeledUserAvatar
                  user={project.owner}
                  showAvatar={false}
                />
              </TableCell>
            )}
            <TableCell>
              <LabeledUserAvatar
                user={project.manager}
                showAvatar={false}
              />
            </TableCell>
            <TableCell>
              {this.getProjectPhase()}
            </TableCell>
            <TableCell>
              {this.getParticipantsCount()}
            </TableCell>
            <TableCell>
              {project.inclusions_count}
            </TableCell>
            {admin && (
              <TableCell>
                <FormattedValue value={moment(project.updated_at).format('DD/MM/YYYY')} />
              </TableCell>
            )}
          </TableRow>
        </MixedView>
      ),
      (
        <MobileView key={1}>
          <ul className="list-group project-list-group mobile-list-item">
            <li
              onClick={this.open}
              role="menuitem"
              className={this.isAdminProject()
                ? 'admin-project-item'
                : 'list-item project-list-item'}
              tabIndex={0}
              onKeyUp={this.handleKeyUp}
            >
              <div className="col-sm-6 text-center font-weight-semibold project-list project-list-name">
                {project.name}
              </div>
            </li>
            <li
              onClick={this.open}
              role="menuitem"
              className={this.isAdminProject()
                ? 'admin-project-item'
                : 'list-item project-list-item'}
              tabIndex={0}
              onKeyUp={this.handleKeyUp}
            >
              <div className="col-sm-6 text-center font-italic project-list project-list-inclusions">
                Number of inclusions :&nbsp;
                {project.inclusions_count}
              </div>
            </li>
          </ul>
        </MobileView>
      ),
    ] : [
      (
        <MixedView key={0}>
          <TableRow key="no-one" className="no-one">
            <TableCell
              colSpan="8"
            >
              {t('project:no.project')}
            </TableCell>
          </TableRow>
        </MixedView>
      ),
      (
        <MobileView key={1}>
          <div key="no-one" className="no-one pt-3">
            <div
              colSpan="8"
              className="align-middle text-center"
            >
              {t('project:no.project')}
            </div>
          </div>
        </MobileView>
      ),
    ];
  }
}


export default ProjectListItem;
