/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Label } from 'reactstrap';
import Fuse from 'fuse.js';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import {
  usersActions, teamsActions, userLicensesActions, teamLicensesActions, projectsActions,
} from '../redux/actions';
import Head from '../components/AdminHead';
import { CardLoader } from '../components/Loader';
import Pagination from '../components/Pagination';
import NewModal from '../components/NewModal';
import LabeledChoice, { eventToMultipleChoicesValue } from '../components/LabeledChoice';
import LabeledInput from '../components/LabeledInput';
import LabeledSelect from '../components/LabeledSelect';
import { TeamLicenseProductsTable } from './LicenseProducts';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import usePrevious from '../utils/CustomHooks';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { isProjectDisabled } from '../utils/data-util';
import {
  TEAM_LICENSE_ADVANCED_KEY, TEAM_LICENSE_EXPERT_KEY, TEAM_LICENSE_PRO_KEY,
  TEAM_LICENSE_RESIDENTS_KEY, USER_LICENSE_ADVANCED_KEY, USER_LICENSE_ASSISTANT_KEY,
  USER_LICENSE_ASSOCIATION_KEY, USER_LICENSE_EXPERT_KEY, USER_LICENSE_HEALTHCARE_FACILITY_KEY,
  USER_LICENSE_INDUSTRIALIST_KEY, USER_LICENSE_INTERN_KEY, USER_LICENSE_PRO_KEY,
} from '../utils/license';

const SUBSCRIBERS_PER_PAGE = 10;
const DEFAULT_VALUE = 'Default';
const CUSTOM_VALUE = 'Custom';
const CONCOMITANT_VALUE = 'Concomitant';
const PAGE_SIZE = 10;
const KEY_SEPARATOR = '-';
const KEY_TYPE_USER = 'user';
const KEY_TYPE_TEAM = 'team';

const toKey = (type, id) => `${type}${KEY_SEPARATOR}${id}`;
const keyToType = (key) => key.split(KEY_SEPARATOR)[0];
const keyToId = (key) => Number(key.split(KEY_SEPARATOR)[1]);

const formatDefault = (value) => `${value} (${DEFAULT_VALUE})`;
const formatCustom = (value) => `${value} (${CUSTOM_VALUE})`;
const formatConcomitant = (value) => `${value} (${CONCOMITANT_VALUE})`;

const userLicencesStdUpgradeList = [
  USER_LICENSE_PRO_KEY,
  USER_LICENSE_ADVANCED_KEY,
  USER_LICENSE_EXPERT_KEY,
];
const userLicencesUpgradeMap = {
  [USER_LICENSE_ASSISTANT_KEY]: [USER_LICENSE_ASSISTANT_KEY],
  [USER_LICENSE_INTERN_KEY]: [USER_LICENSE_INTERN_KEY],
  [USER_LICENSE_PRO_KEY]: userLicencesStdUpgradeList,
  [USER_LICENSE_ADVANCED_KEY]: userLicencesStdUpgradeList,
  [USER_LICENSE_EXPERT_KEY]: userLicencesStdUpgradeList,
  [USER_LICENSE_ASSOCIATION_KEY]: [USER_LICENSE_ASSOCIATION_KEY],
  [USER_LICENSE_INDUSTRIALIST_KEY]: [USER_LICENSE_INDUSTRIALIST_KEY],
  [USER_LICENSE_HEALTHCARE_FACILITY_KEY]: [USER_LICENSE_HEALTHCARE_FACILITY_KEY],
};
const teamLicencesStdUpgradeList = [
  TEAM_LICENSE_PRO_KEY,
  TEAM_LICENSE_ADVANCED_KEY,
  TEAM_LICENSE_EXPERT_KEY,
  TEAM_LICENSE_RESIDENTS_KEY,
];
const teamLicencesUpgradeMap = {
  [TEAM_LICENSE_PRO_KEY]: teamLicencesStdUpgradeList,
  [TEAM_LICENSE_ADVANCED_KEY]: teamLicencesStdUpgradeList,
  [TEAM_LICENSE_EXPERT_KEY]: teamLicencesStdUpgradeList,
  [TEAM_LICENSE_RESIDENTS_KEY]: teamLicencesStdUpgradeList,
};

const getLicenceName = (t, key, team) => (
  t(`user:licenses.${team ? 'team-' : ''}${key}`)
);

const getEffectiveLim = (subsLim, licLim, subscriber, licenceKey, licences) => {
  if (licenceKey === subscriber.licence) return subscriber[subsLim];
  const licence = licences.find((lic) => lic.key === licenceKey);
  return licence[licLim];
};

const SelectionLink = (props) => (
  <button
    className="btn btn-link"
    type="button"
    onClick={props.onClick}
  >
    {props.children}
  </button>
);

SelectionLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: childrenPropTypes().isRequired,
};

const LicenceModal = (props) => {
  const {
    children, updateSubscriber, subscriber, licences,
  } = props;
  const { t } = useTranslation();

  const [licence, setLicence] = useState(subscriber.licence);

  const prevSubscriber = usePrevious(subscriber);

  const extraInvestigators = Number(subscriber.extra_investigators);
  const customMaxProjects = Number(subscriber.custom_max_projects);
  const defaultExtraInvestigators = Number(getEffectiveLim(
    'default_extra_investigators',
    'max_investigators_per_project',
    subscriber,
    licence,
    licences,
  ));
  const defaultCustomMaxProjects = Number(getEffectiveLim(
    'default_custom_max_projects',
    'max_project_creations',
    subscriber,
    licence,
    licences,
  ));
  const {
    can_own_licence_products: canOwnLicenceProducts,
    is_under_licensed_product: isUnderLicensedProduct,
    owner_can_own_licence_products: ownerCanOwnLicenceProducts,
    owner_use_concomitant_active_project: ownerUseConcomitantActiveProject,
    owner_max_project_creation: ownerMaxProjectCreation,
    owner_active_project_count: ownerActiveProjectCount,
  } = subscriber;

  const { handleCatched } = useError();
  const { error } = useToast();
  const refModal = useRef(null);
  const pjts = useSelector((state) => state.projects);
  const projects = useMemo(() => Object.values(pjts)).sort((a, b) => (
    a.is_disabled && !b.is_disabled ? 1 : -1
  ), [pjts]);

  const dispatch = useDispatch();
  const clearProjects = () => dispatch(projectsActions.removeAllSuccess());
  const fetchProjects = async (params) => dispatch(projectsActions.list({
    ...params,
    admin: true,
  }, { pagination: 'no' }));

  const initialState = {
    extraInvestigatorsCount: Number.isNaN(extraInvestigators) ? '' : String(extraInvestigators),
    customizeProjectsCount: !Number.isNaN(customMaxProjects),
    customProjectsCount: Number.isNaN(customMaxProjects) ? '' : String(customMaxProjects),
    selectedProjects: [],
    activeProjects: [],
    frozenProjects: [],
    dataLoading: false,
  };

  const [extraInvestigatorsCount, setExtraInvestigatorsCount] = useState(
    initialState.extraInvestigatorsCount,
  );
  const [customizeProjectsCount, setCustomizeProjectsCount] = useState(
    initialState.customizeProjectsCount,
  );
  const [customProjectsCount, setCustomProjectsCount] = useState(initialState.customProjectsCount);
  const [selectedProjects, setSelectedProjects] = useState(initialState.selectedProjects);
  const [activeProjects, setActiveProjects] = useState(initialState.activeProjects);
  const [frozenProjects, setFrozenProjects] = useState(initialState.frozenProjects);
  const [dataLoading, setDataLoading] = useState(initialState.dataLoading);

  let effectiveMaxProjects = customizeProjectsCount ? Number(customProjectsCount)
    : defaultCustomMaxProjects;

  let effectiveOwnerOtherActiveProjectCount = 0;

  if (ownerUseConcomitantActiveProject) {
    effectiveMaxProjects = ownerMaxProjectCreation;
    effectiveOwnerOtherActiveProjectCount = ownerActiveProjectCount - activeProjects.length;
  }

  const reset = () => {
    setExtraInvestigatorsCount(initialState.extraInvestigatorsCount);
    setCustomizeProjectsCount(initialState.customizeProjectsCount);
    setCustomProjectsCount(initialState.customProjectsCount);
    setSelectedProjects(initialState.selectedProjects);
    setFrozenProjects(initialState.frozenProjects);
    setDataLoading(initialState.dataLoading);
  };

  useEffect(() => {
    if (prevSubscriber && !isEqual(prevSubscriber, subscriber)) {
      setLicence(subscriber.licence);
      reset();
    }
  }, [subscriber]);

  const validate = async () => {
    if (customizeProjectsCount && customProjectsCount === '') {
      error('The number of custom max projects is missing.');
      return;
    }
    if (selectedProjects.length > effectiveMaxProjects - effectiveOwnerOtherActiveProjectCount) {
      error(`The number of active project must be less or equal to ${effectiveMaxProjects}.`);
      return;
    }
    const projectsToToggleActivation = projects.filter((project) => (
      !frozenProjects.includes(String(project.id))
        && project.is_disabled === selectedProjects.includes(String(project.id))
    ));
    await updateSubscriber(
      subscriber.id,
      licence,
      extraInvestigatorsCount || '0',
      customizeProjectsCount ? customProjectsCount : undefined,
      projectsToToggleActivation,
    );
    if (refModal.current) refModal.current.hide();
  };

  const cancel = () => {
    if (refModal.current) refModal.current.hide();
  };

  const fetchData = async () => {
    try {
      clearProjects();
      setDataLoading(true);
      const type = keyToType(subscriber.id);
      const id = keyToId(subscriber.id);
      const params = { };
      if (type === KEY_TYPE_USER) {
        params.owner = id;
        params.team__isnull = true;
      } else if (type === KEY_TYPE_TEAM) {
        params.team = id;
      } else {
        throw new Error(`Unkonwn subscriber type (${type}).`);
      }
      const res = await fetchProjects(params);
      setActiveProjects(res.filter((project) => !isProjectDisabled(project)).map((project) => (
        String(project.id)
      )));
      setSelectedProjects(res.filter((project) => !isProjectDisabled(project)).map((project) => (
        String(project.id)
      )));
      setFrozenProjects(res.filter((project) => project.is_frozen).map((project) => (
        String(project.id)
      )));
    } catch (err) {
      handleCatched(props, err);
    } finally {
      setDataLoading(false);
    }
  };

  const renderLicence = () => (
    <LabeledSelect
      label={(
        <span>
          Licence:
          {isUnderLicensedProduct && (
            <span className="ml-2 text-red font-weight-normal small font-italic">
              <strong>
                Under licensed product:
              </strong>
              &nbsp;
              Manage this license in the institution account
            </span>
          )}
          {!isUnderLicensedProduct && ownerCanOwnLicenceProducts && (
            <span className="ml-2 text-red font-weight-normal small font-italic">
              <strong>
                The owner can own licensed products:
              </strong>
              &nbsp;
              Manage this license in the institution account
            </span>
          )}
        </span>
      )}
      labelClassName="font-weight-semibold lead-licenses-modal"
      type="text"
      hideOptionalLabel
      name="licences"
      value={licence}
      onChange={(event) => setLicence(event.target.value)}
      disabled={isUnderLicensedProduct || ownerCanOwnLicenceProducts}
    >
      {licences.map((lic) => (
        <option value={lic.key} key={lic.key}>
          {getLicenceName(t, lic.key, subscriber.team)}
        </option>
      ))}
    </LabeledSelect>
  );

  const renderExtraInvestigators = () => (
    <>
      <div className="row">
        <div className="col-12">
          <Label
            className="font-weight-semibold lead-licenses-modal"
          >
            Maximum number of external investigators:
          </Label>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <span>
            {'Default: '}
          </span>
          <span>
            {defaultExtraInvestigators}
          </span>
        </div>
        <div className="col">
          <LabeledInput
            label="Extra investigators: "
            colLabelClassName="col-auto pt-2"
            colInputClassName="col"
            name="extra-investigators-count"
            value={extraInvestigatorsCount}
            hideOptionalLabel
            type="number"
            placeholder="Enter the number of extra investigators."
            onChange={(e) => setExtraInvestigatorsCount(e.target.value)}
            disabled={isUnderLicensedProduct || ownerCanOwnLicenceProducts}
          />
        </div>
      </div>
    </>
  );

  const renderCustomProjectsCount = () => (
    <>
      <div className="row">
        <div className="col-12">
          <Label className="font-weight-semibold lead-licenses-modal">
            Max number of projects:
          </Label>
        </div>
      </div>
      {((isUnderLicensedProduct || ownerCanOwnLicenceProducts)
        && ownerUseConcomitantActiveProject)
        && (
          <div>
            {ownerMaxProjectCreation} active concurrent projects.
            <div className="small text-gray">
              The institution&lsquo;s account has activated the concurrent projects option.
            </div>
          </div>
        )}
      {!((isUnderLicensedProduct || ownerCanOwnLicenceProducts)
        && ownerUseConcomitantActiveProject)
        && (
          <div className="row">
            <div className="col-auto">
              <LabeledChoice
                name="custom-projects-count"
                value={customizeProjectsCount}
                choices={[{
                  text: `${DEFAULT_VALUE} (${defaultCustomMaxProjects})`,
                  value: false,
                }, {
                  text: CUSTOM_VALUE,
                  value: true,
                }]}
                onChange={(e) => setCustomizeProjectsCount(e.target.value === 'true')}
                disabled={isUnderLicensedProduct || ownerCanOwnLicenceProducts}
              />
            </div>
            {customizeProjectsCount && (
            <div className="col">
              <LabeledInput
                name="custom-projects-count"
                value={customProjectsCount}
                type="number"
                placeholder="Enter the max number of project creations."
                onChange={(e) => setCustomProjectsCount(e.target.value)}
                disabled={isUnderLicensedProduct || ownerCanOwnLicenceProducts}
              />
            </div>
            )}
          </div>
        )}
    </>
  );

  const renderSelectedProjects = () => (
    <>
      <div className="row">
        <div className="col-12">
          <Label>
            <span className="font-weight-semibold lead-licenses-modal">
              Active projects:
            </span>
            <span
              className={`font-weight-semibold${selectedProjects.length > (effectiveMaxProjects - effectiveOwnerOtherActiveProjectCount) ? ' text-red' : ''}`}
            >
              {` ${selectedProjects.length + effectiveOwnerOtherActiveProjectCount} / ${effectiveMaxProjects}`}
            </span>
            {Boolean(projects && projects.length) && (
              <div className="small text-gray">
                Manage active projects or validate to launch automatic activation.
                Projects over limitations cannot be checked.
              </div>
            )}
          </Label>
        </div>
      </div>
      {Boolean(projects && projects.length) && (
        <div className="row">
          <div className="col-12">
            <LabeledChoice
              colInputClassName="col-12 ml-2"
              className="border selected-projects mb-0"
              name="selected-projects"
              hideOptionalLabel
              multipleChoices
              orientation="vertical"
              choices={projects.map((project) => ({
                text: project.name,
                value: String(project.id),
              }))}
              disabledChoices={frozenProjects}
              value={selectedProjects}
              onChange={(e) => (
                setSelectedProjects(eventToMultipleChoicesValue(e, selectedProjects))
              )}
            />
          </div>
          <div className="row">
            <div className="col-auto pr-0">
              <SelectionLink
                onClick={() => setSelectedProjects(projects.filter((project) => (
                  !project.is_frozen
                )).map((project) => String(project.id)))}
              >
                Select all
              </SelectionLink>
            </div>
            <div className="col-auto px-0">
              <SelectionLink
                onClick={() => setSelectedProjects([])}
              >
                Deselect all
              </SelectionLink>
            </div>
          </div>
        </div>
      )}
    </>
  );

  let title = `${getLicenceName(t, subscriber.licence, subscriber.team)} - ${subscriber.user}`;

  if (subscriber.team) {
    title = `${title} - ${subscriber.team}`;
  }

  return (
    <NewModal
      trigger={children}
      title={title}
      extraClass="contains-loader"
      onLoad={fetchData}
      onClosed={reset}
      size={canOwnLicenceProducts ? 'lg' : 'md'}
      footer={canOwnLicenceProducts ? null : (
        <div className="row">
          <button
            type="button"
            className="btn btn-primary text-white px-3"
            onClick={validate}
          >
            Validate
          </button>
          <button
            type="button"
            className="btn btn-link ml-2"
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
      )}
      ref={(modal) => { refModal.current = modal; }}
    >
      { dataLoading && <CardLoader /> }
      <div>
        {renderLicence()}
      </div>
      {canOwnLicenceProducts ? (
        <div className="mt-4 mb-5">
          <Label className="font-weight-semibold lead-licenses-modal">License products</Label>
          <TeamLicenseProductsTable
            className="mt-1"
            owner={{
              ...subscriber,
              id: keyToId(subscriber.id),
            }}
            admin
          />
        </div>
      ) : (
        <>
          <div className="mt-4">
            {renderExtraInvestigators()}
          </div>
          <div className="mt-3">
            {renderCustomProjectsCount()}
          </div>
          <div className="mt-3">
            {renderSelectedProjects()}
          </div>
        </>
      )}
    </NewModal>
  );
};

LicenceModal.propTypes = {
  children: childrenPropTypes().isRequired,
  subscriber: PropTypes.shape().isRequired,
  licences: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSubscriber: PropTypes.func.isRequired,
};

const AdminLicences = (props) => {
  const { handleCatched } = useError();
  const { success } = useToast();
  const { t } = useTranslation();

  const refFuse = useRef(null);

  const users = useSelector((state) => state.users);
  const teams = useSelector((state) => state.teams);
  const userLicences = useSelector((state) => state.userLicenses);
  const teamLicences = useSelector((state) => state.teamLicenses);

  const dispatch = useDispatch();

  const fetchUsers = async () => dispatch(usersActions.list({ admin: true }, { pagination: 'no' }));
  const patchUser = async (id, data) => dispatch(usersActions.patch(id, data, { admin: true }));
  const fetchTeams = async () => dispatch(teamsActions.list({ admin: true }, { pagination: 'no' }));
  const patchTeam = async (id, data) => dispatch(teamsActions.patch(id, data, { admin: true }));
  const fetchUserLicences = async () => (
    dispatch(userLicensesActions.list({ admin: true }, { pagination: 'no' }))
  );
  const fetchTeamLicences = async () => (
    dispatch(teamLicensesActions.list({ admin: true }, { pagination: 'no' }))
  );
  const patchProject = async (id, data) => (
    dispatch(projectsActions.patch(id, data, { admin: true }))
  );

  const [columns, setColumns] = useState([]);
  const [fullSubscribers, setFullSubscribers] = useState([]);
  const [filteredSubscribers, setFilteredSubscribers] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [totalSubscribersCount, setTotalSubscribersCount] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [licenceFilter, setLicenceFilter] = useState([]);
  const [ready, setReady] = useState(false);

  const updateSubscriber = async (subcriberKey, licence, extraInvestigatorsCount,
    customProjectsCount, projectsToToggleActivation,
  ) => {
    const type = keyToType(subcriberKey);
    const id = keyToId(subcriberKey);
    const subscriber = type === KEY_TYPE_USER ? users[id] : teams[id];
    const patchSubscriber = type === KEY_TYPE_USER ? patchUser : patchTeam;
    let licencePatch = {};
    const licenceOptionsPatch = { ...subscriber.licence_options };
    const currentExtraInvestigators = Number(
      subscriber.licence_options.max_investigators_per_project,
    );
    const currentMaxProjectCreations = Number(subscriber.licence_options.max_project_creations);
    const newExtraInvestigators = Number(extraInvestigatorsCount);
    const newMaxProjectCreations = Number(customProjectsCount);

    if (subscriber.licence !== licence) {
      const licenceId = Object.values(
        type === KEY_TYPE_USER ? userLicences : teamLicences,
      ).find((lic) => lic.key === licence).id;
      licencePatch = type === KEY_TYPE_USER ? { groups: [licenceId] } : { licence: licenceId };
    }
    if (currentExtraInvestigators !== newExtraInvestigators) {
      if (!Number.isNaN(newExtraInvestigators)) {
        licenceOptionsPatch.max_investigators_per_project = newExtraInvestigators;
      } else {
        delete licenceOptionsPatch.max_investigators_per_project;
      }
    }
    if (currentMaxProjectCreations !== newMaxProjectCreations) {
      if (!Number.isNaN(newMaxProjectCreations)) {
        licenceOptionsPatch.max_project_creations = newMaxProjectCreations;
      } else {
        delete licenceOptionsPatch.max_project_creations;
      }
    }
    try {
      await Promise.all(projectsToToggleActivation.map((project) => (
        patchProject(project.id, { is_disabled: !project.is_disabled })
      )));
      if (Object.keys(licencePatch).length || Object.keys(licenceOptionsPatch).length) {
        await patchSubscriber(id, {
          ...licencePatch,
          licence_options: licenceOptionsPatch,
        });
      }
      success('error:valid.success');
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const getFilteredLicenses = (row, licenses, upgradeMap) => (
    // Filter according to upgradeMap (map of allowed licence upgrades)
    Object.values(licenses).filter((lic) => upgradeMap[row.licence].includes(lic.key))
  );

  const getTableColumns = () => [{
    dataField: 'id',
    text: 'Identifier',
    hidden: true,
  }, {
    dataField: 'licence',
    text: 'License',
    sort: true,
    formatter: (cellContent, row) => getLicenceName(t, cellContent, row.team),
  }, {
    dataField: 'user',
    text: 'Managing account',
    sort: true,
    style: { fontWeight: 600 },
  }, {
    dataField: 'team',
    text: 'Team',
    sort: true,
    formatter: (cellContent) => cellContent || '-',
    style: { fontWeight: 600 },
  }, {
    dataField: 'extra_investigators',
    text: 'Max external investigators',
    sort: true,
    formatter: (cellContent, row) => (
      cellContent ? formatCustom(row.default_extra_investigators + cellContent)
        : formatDefault(row.default_extra_investigators)
    ),
    headerStyle: { width: '140px' },
  }, {
    dataField: 'custom_max_projects',
    text: 'Max nb of projects',
    sort: true,
    formatter: (cellContent, row) => {
      if (row.owner_use_concomitant_active_project) {
        return formatConcomitant(row.owner_max_project_creation);
      }

      if (row.use_concomitant_active_project) {
        return formatConcomitant(cellContent);
      }

      if (cellContent !== undefined) {
        return formatCustom(cellContent);
      }

      return formatDefault(row.default_custom_max_projects);
    },
    headerStyle: { width: '140px' },
  }, {
    dataField: 'default_extra_investigators',
    text: 'Default extra investigators',
    sort: true,
    hidden: true,
  }, {
    dataField: 'default_custom_max_projects',
    text: 'Default custom nb of projects',
    sort: true,
    hidden: true,
  }, {
    dataField: 'can_own_licence_products',
    text: 'Can own licensed products',
    sort: false,
    editable: false,
    hidden: true,
  }, {
    dataField: 'is_under_licensed_product',
    text: 'Is under licensed product',
    sort: false,
    editable: false,
    hidden: true,
  }, {
    dataField: 'owner_can_own_licence_products',
    text: 'Owner can own licensed product',
    sort: false,
    editable: false,
    hidden: true,
  }, {
    dataField: 'unused_licences_count',
    text: 'Unused licenses count',
    sort: false,
    editable: false,
    hidden: true,
  }, {
    dataField: 'active_licences_count',
    text: 'Active licenses count',
    sort: false,
    editable: false,
    hidden: true,
  }, {
    dataField: 'disabled_licences_count',
    text: 'Disabled licenses count',
    sort: false,
    editable: false,
    hidden: true,
  }, {
    dataField: 'use_concomitant_active_project',
    text: 'Use concomitant active project',
    sort: false,
    editable: false,
    hidden: true,
  }, {
    dataField: 'edit',
    text: 'Edit',
    isDummyField: true,
    sort: false,
    headerStyle: { width: '60px' },
    editable: false,
    align: 'center',
    formatter: (cellContent, row) => (
      <LicenceModal
        subscriber={row}
        licences={getFilteredLicenses(
          row,
          row.team ? teamLicences : userLicences,
          row.team ? teamLicencesUpgradeMap : userLicencesUpgradeMap,
        )}
        updateSubscriber={updateSubscriber}
      >
        <button
          type="button"
          className="overmenu-item p-0"
        >
          <FontAwesomeIcon icon="pen" />
        </button>
      </LicenceModal>
    ),
  }];

  const selectAllLicenceFilters = (userLicencesArray, teamLicencesArray) => {
    setLicenceFilter([
      ...userLicencesArray.map((lic) => toKey(KEY_TYPE_USER, lic.key)),
      ...teamLicencesArray.map((lic) => toKey(KEY_TYPE_TEAM, lic.key)),
    ]);
  };

  const fetchData = async () => {
    try {
      setDataLoading(true);
      const res = await Promise.all([
        fetchUsers(),
        fetchTeams(),
        fetchUserLicences(),
        fetchTeamLicences(),
      ]);
      setReady(true);
      selectAllLicenceFilters(res[2], res[3]);
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setDataLoading(false);
    }
  };

  const buildData = () => {
    if (!ready) return;

    const usersArray = Object.values(users);
    const teamsArray = Object.values(teams);

    const data = [
      ...usersArray.map((usr) => ({
        id: toKey(KEY_TYPE_USER, usr.id),
        licence: userLicences[usr.groups[0]].key,
        user: usr.label,
        team: null,
        can_own_licence_products: usr.limitations.can_own_licence_products,
        is_under_licensed_product: false,
        owner_can_own_licence_products: false,
        extra_investigators: usr.licence_options.max_investigators_per_project,
        custom_max_projects: usr.licence_options.max_project_creations,
        default_extra_investigators: userLicences[usr.groups[0]].max_investigators_per_project,
        default_custom_max_projects: userLicences[usr.groups[0]].max_project_creations,
        created_at: usr.date_joined,
        active_licences_count: usr.active_licences_count,
        disabled_licences_count: usr.disabled_licences_count,
        unused_licences_count: usr.unused_licences_count,
        use_concomitant_active_project: usr.licence_options.use_concomitant_active_project
          ?? usr.limitations.use_concomitant_active_project,
      })),
      ...teamsArray.map((team) => ({
        id: toKey(KEY_TYPE_TEAM, team.id),
        licence: teamLicences[team.licence].key,
        user: users[team.owner.id].label,
        team: team.name,
        can_own_licence_products: false,
        is_under_licensed_product: team.is_under_licensed_product || false,
        owner_can_own_licence_products: team.owner.limitations.can_own_licence_products || false,
        extra_investigators: team.licence_options.max_investigators_per_project,
        custom_max_projects: team.licence_options.max_project_creations,
        default_extra_investigators: teamLicences[team.licence].max_investigators_per_project,
        default_custom_max_projects: teamLicences[team.licence].max_project_creations,
        created_at: team.created_at,
        active_licences_count: null,
        disabled_licences_count: null,
        unused_licences_count: null,
        owner_use_concomitant_active_project: team.owner.limitations
          .use_concomitant_active_project || false,
        owner_max_project_creation: team.owner.limitations.max_project_creations,
        owner_active_project_count: team.owner.active_project_owner_count,
      })),
    ];

    data.sort((a, b) => (
      moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1
    ));

    setFullSubscribers(data);
    refFuse.current = new Fuse(data, { keys: ['user', 'team'], threshold: 0.4 });
  };

  const filterData = () => {
    let filteredSbcb = fullSubscribers;
    if (refFuse.current && searchValue) {
      filteredSbcb = refFuse.current.search(searchValue).map((result) => result.item);
    }
    filteredSbcb = filteredSbcb.filter((subscriber) => (
      licenceFilter.includes(toKey(
        subscriber.team ? KEY_TYPE_TEAM : KEY_TYPE_USER,
        subscriber.licence,
      ))
    ));
    setFilteredSubscribers(filteredSbcb);
  };

  const paginateData = () => {
    const startIndex = SUBSCRIBERS_PER_PAGE * (currentPage - 1);
    const endIndex = SUBSCRIBERS_PER_PAGE * currentPage;
    setSubscribers(filteredSubscribers.slice(startIndex, endIndex));
    setTotalSubscribersCount(filteredSubscribers.length);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setCurrentPage(1);
    setSearchValue(value);
  };

  const filterByLicence = (event) => {
    setCurrentPage(1);
    setLicenceFilter(eventToMultipleChoicesValue(event, licenceFilter));
  };

  useEffect(() => {
    setColumns(getTableColumns());
    fetchData();
  }, []);

  useEffect(() => {
    setColumns(getTableColumns()); // Needed to refresh closures
    buildData();
  }, [ready, users, teams]);

  useEffect(() => {
    filterData();
  }, [searchValue, licenceFilter, fullSubscribers]);

  useEffect(() => {
    paginateData();
  }, [currentPage, filteredSubscribers]);

  return (
    <div>
      <Head
        title="Doqboard licenses"
        subTitle="Find here Doqboard licenses."
        admin
        {...props}
      />
      <div className="dashboard-content">
        <div className="row">
          <div className="col-12">
            <LabeledInput
              type="text"
              label="Search managing account or team"
              name="search"
              labelClassName="mb-2 font-weight-semibold"
              placeholder="Enter your pattern..."
              value={searchValue}
              hideOptionalLabel
              onChange={handleSearch}
            />
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-12">
                <LabeledChoice
                  label="Filter by license"
                  labelClassName="font-weight-semibold"
                  className="mb-0"
                  name="extra-"
                  value={licenceFilter}
                  choices={[
                    ...Object.values(userLicences).map((lic) => ({
                      text: getLicenceName(t, lic.key, false),
                      value: toKey(KEY_TYPE_USER, lic.key),
                    })),
                    ...Object.values(teamLicences).map((lic) => ({
                      text: getLicenceName(t, lic.key, true),
                      value: toKey(KEY_TYPE_TEAM, lic.key),
                    })),
                  ]}
                  multipleChoices
                  hideOptionalLabel
                  onChange={filterByLicence}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-auto px-0">
                <SelectionLink
                  onClick={() => (
                    selectAllLicenceFilters(
                      Object.values(userLicences),
                      Object.values(teamLicences),
                    )
                  )}
                >
                  Select all
                </SelectionLink>
              </div>
              <div className="col-auto px-0">
                <SelectionLink
                  onClick={() => setLicenceFilter([])}
                >
                  Deselect all
                </SelectionLink>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4 mt-3 font-weight-semibold">
            <span>
              {'Total number of filtered licenses: '}
            </span>
            <span>
              {totalSubscribersCount}
            </span>
          </div>
        </div>
        <div className="contains-loader mt-5">
          { dataLoading && <CardLoader /> }
          {Boolean(columns.length) && (
            <BootstrapTable
              keyField="id"
              data={subscribers}
              columns={columns}
              headerClasses="admin_table"
              wrapperClasses="admin_table"
              bootstrap4
              hover
            />
          )}
        </div>
        <nav className="my-5">
          <Pagination
            page={currentPage}
            count={totalSubscribersCount}
            pageSize={PAGE_SIZE}
            action={(page) => setCurrentPage(page)}
          />
        </nav>
      </div>
    </div>
  );
};

export default AdminLicences;
