import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { nsOptions } from '../i18n';

@withToastManager
@withTranslation('', nsOptions)
class StdLicenseMessage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    LicMsgTitle: PropTypes.string,
    LicMsgText: PropTypes.string,
  };

  static defaultProps = {
    LicMsgTitle: 'error:license.need-upgrade',
    LicMsgText: 'user:license.upgrade-info',
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      t,
      LicMsgTitle,
      LicMsgText,
    } = this.props;

    return (
      <>
        <h4 className="text-center">
          { t(LicMsgTitle) }
        </h4>
        <br />
        <div className="text-center mt-2">
          { t(LicMsgText) }
        </div>
      </>
    );
  }
}


export default StdLicenseMessage;
