import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { nsOptions } from '../i18n';
import MixedView from './MixedView';
import MobileView from './MobileView';
import ProjectListItem from './ProjectListItem';
import ViewMoreTableFooter from './ViewMoreTableFooter';
import {
  Table, TableRow, TableHead, TableCell, TableBody,
} from './CustomTable';

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
});

@connect(mapStateToProps)
@withTranslation('', nsOptions)
class SimpleProjectListTable extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  constructor(props) {
    super(props);
    this.getContent = memoize(this.loadProjects);
  }

  loadProjects(projects) {
    let content = [];
    if (projects) {
      content = Object.keys(projects).map((id) => (
        <ProjectListItem
          {...this.props}
          key={id}
          project={projects[id]}
          showLastChangeDate
        />
      ));
    }
    if (content.length <= 0) {
      content.push(<ProjectListItem
        {...this.props}
        key="none"
      />);
    }
    return content;
  }

  render() {
    const { t, user, projects } = this.props;

    const showTeam = user && user.limitations.can_own_licence_products;
    const showOwner = user && (user.limitations.can_join_project_as_external
      || user.limitations.can_join_project_in_team);

    return (
      <div>
        <MixedView>
          <Table extraClassName="mt-3 mb-0">
            <TableHead>
              <TableRow>
                <TableCell tag="th">
                  {t('project:project')}
                </TableCell>
                {showTeam && (
                  <TableCell tag="th">
                    {t('project:project-team')}
                  </TableCell>
                )}
                {showOwner && (
                  <TableCell tag="th">
                    {t('project:owner')}
                  </TableCell>
                )}
                <TableCell tag="th">
                  {t('project:manager')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:status')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:participants')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:inclusions.mine')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.getContent(projects)}
            </TableBody>
            <ViewMoreTableFooter url="/dashboard/projects" />
          </Table>
        </MixedView>
        <MobileView>
          <div>
            {this.getContent(projects)}
          </div>
        </MobileView>
      </div>
    );
  }
}


export default SimpleProjectListTable;
