import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import {
  CAN_EDIT_FORM_AND_DOCUMENTATIONS, LOCK_MODEL_PROJECT, LOCK_SECTION, LOCK_SECTION_DESCRIPTION,
  LOCK_TOAST_TIMEOUT_S, LOCK_SECTION_RESOURCES,
} from '../constants';
import { nsOptions } from '../i18n';
import TextUtil from '../utils/TextUtil';
import { hasPermission, DataLocker, isProjectDisabled } from '../utils/data-util';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import Editor from './Editor';
import ResourceList from './ResourceList';
import { isTabletView } from './TabletView';
import EditButton from './EditButton';
import { projectUsersActions } from '../redux/actions';


const mapStateToProps = (state, ownProps) => ({
  user: state.auth.authUser,
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user && pUser.user.id === state.auth.authUser.id
      && pUser.project === ownProps.project.id
  )),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjectUsers: async () => dispatch(projectUsersActions.list({
    project: ownProps.project.id,
    admin: ownProps.admin,
  }, { pagination: 'no' })),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ProjectResources extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    project: PropTypes.shape().isRequired,
    saveInput: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    admin: PropTypes.bool,
    fetchProjectUsers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    admin: false,
    projectUser: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      descriptionEditing: false,
      resourcesEditing: false,
    };
    this.descriptionLocker = new DataLocker(LOCK_MODEL_PROJECT, LOCK_SECTION, props.admin);
    this.resourcesLocker = new DataLocker(LOCK_MODEL_PROJECT, LOCK_SECTION, props.admin);
  }

  async componentDidMount() {
    const { projectUser, fetchProjectUsers } = this.props;
    if (!projectUser) {
      await fetchProjectUsers();
    }
  }

  componentWillUnmount() {
    if (this.descriptionLocker.isLocked()) this.descriptionLocker.unlock();
    if (this.resourcesLocker.isLocked()) this.resourcesLocker.unlock();
  }

  canEdit = () => {
    const { projectUser, admin, project } = this.props;
    return Boolean(admin || (!isProjectDisabled(project) && hasPermission(projectUser,
      CAN_EDIT_FORM_AND_DOCUMENTATIONS)));
  }

  onDescriptionEditionToggle = async () => {
    const { t, project } = this.props;

    try {
      const res = await this.descriptionLocker.toggleLock(project.id, LOCK_SECTION_DESCRIPTION);
      const { locked, success } = res;
      if (success) {
        this.setState({ descriptionEditing: locked });
      } else {
        Toast.warning(this.props, DataLocker.lockUserMessage(res, t), LOCK_TOAST_TIMEOUT_S);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  onResourcesEditionToggle = async () => {
    const { t, project } = this.props;

    try {
      const res = await this.resourcesLocker.toggleLock(project.id, LOCK_SECTION_RESOURCES);
      const { locked, success } = res;
      if (success) {
        this.setState({ resourcesEditing: locked });
      } else {
        Toast.warning(this.props, DataLocker.lockUserMessage(res, t), LOCK_TOAST_TIMEOUT_S);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  render() {
    const { t, project, saveInput } = this.props;
    const { descriptionEditing, resourcesEditing } = this.state;
    const canEdit = this.canEdit();

    return (
      <div>
        <div className="new-settings">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 d-flex align-items-center">
              <Label className="field-label mt-0">
                {t('project:description')}
              </Label>
              {
                canEdit && (
                  <EditButton
                    className="ml-2 d-inline"
                    active={descriptionEditing}
                    onClick={this.onDescriptionEditionToggle}
                  />
                )
              }
            </div>
            <div className="col">
              <div className={`mr-5${!isTabletView() ? ' pr-5' : ''}`}>
                {
                  canEdit && descriptionEditing ? (
                    <div className="row mb-2">
                      <div className="col">
                        <div className="fake-input-with-border description-input">
                          <Editor
                            data={project.description}
                            onChange={(data) => { saveInput({ value: data }, 'description', null, 'value', {}, true, false); }}
                            type="classic"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="help-text">
                          {t('project:description-help')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="text-left pt-2"
                      dangerouslySetInnerHTML={{
                        __html: project.description && project.description
                          !== '' && project.description !== '<p>&nbsp;</p>'
                          ? TextUtil.parseLinks(project.description)
                          : `<span class="font-italic">${t('project:no.description')}</span>`,
                      }}
                    />
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 d-flex align-items">
              <Label className="field-label mt-0">
                {t('project:additional-resources')}
              </Label>
              {
                canEdit && (
                  <EditButton
                    className="ml-2"
                    active={resourcesEditing}
                    onClick={this.onResourcesEditionToggle}
                  />
                )
              }
            </div>
            <div className="col-12">
              <div className="">
                <ResourceList
                  {...this.props}
                  resourcesIds={project.resources}
                  saveResources={(resources) => saveInput({ value: resources }, 'resources')}
                  canEdit={canEdit && resourcesEditing}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default ProjectResources;
