import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { projectEntriesActions } from '../redux/actions';
import { getSortingHandlerId } from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import ElementUtil from '../utils/ElementUtil';
import { childrenPropTypes } from '../utils/generic-prop-types';
import SortUtil from '../utils/SortUtil';
import { isInclusionReadOnly } from '../utils/data-util';
import Toast from '../utils/Toast';
import MixedView from './MixedView';
import ElementContentEditable from './ElementContentEditable';
import FormExportManager from './FormExportManager';
import { CardLoader } from './Loader';
import MobileView, { isMobileView } from './MobileView';
import BrowserView from './BrowserView';
import NewModal from './NewModal';
import ButtonConfirm from './ButtonConfirm';
import { DelMsgModal } from './MessageModal';
import NewTooltip from './NewTooltip';
import ErrorUtil from '../utils/ErrorUtil';
import { ELEMENT_TYPE_SPACER, ELEMENT_TYPE_DIVIDER, ELEMENT_DIRECT_IDENTIFIER } from '../constants';
import { checkChildrenLinks, getRootProjectElements } from '../utils/links';

const OtherActionsMenu = (props) => {
  const {
    t, disableTooltip, placement, project, inclusion, moduleInstanceId, projectModule,
    deleteInstance,
  } = props;

  return (
    <NewTooltip
      theme="light"
      trigger="click"
      disable={disableTooltip}
      placement={placement}
      containerClassName="module-header-menu"
      content={(
        <>
          <BrowserView>
            <div>
              <FormExportManager
                project={project}
                inclusion={inclusion}
                moduleInstanceId={moduleInstanceId}
                pageId={projectModule.project_page}
              >
                <button
                  type="button"
                  className="no-button-style dropdown-item"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'file-export']}
                    className="mr-2"
                    transform="grow-2"
                  />
                  {t('inclusion:entry.export-entries')}
                </button>
              </FormExportManager>
            </div>
          </BrowserView>
          <DelMsgModal
            message={(
              <span>
                <b>
                  {t('common:caution')}
                  &nbsp;!
                </b>
                <br />
                <br />
                {t('inclusion:entry.delete-module-instance-warning.part-1')}
                <br />
                <br />
                {t('inclusion:entry.delete-module-instance-warning.part-2')}
              </span>
            )}
            onValidate={deleteInstance}
          >
            <ButtonConfirm>
              <button
                type="button"
                className="no-button-style dropdown-item"
              >
                <span>
                  <FontAwesomeIcon
                    icon={['fal', 'trash-alt']}
                    className="mr-2"
                    transform="grow-4"
                  />
                  {
                    t(isMobileView()
                      ? 'inclusion:entry.delete-module-instance-short'
                      : 'inclusion:entry.delete-module-instance')
                  }
                </span>
              </button>
            </ButtonConfirm>
          </DelMsgModal>
        </>
      )}
      interactive
    >
      <span>
        <NewTooltip
          content={t('inclusion:actions')}
          disabled={isMobileView()}
        >
          <span className="px-3 cursor-pointer align-middle">
            <FontAwesomeIcon
              icon={['fal', 'ellipsis-h']}
              className="text-gray"
              transform="grow-4"
            />
          </span>
        </NewTooltip>
      </span>
    </NewTooltip>
  );
};

OtherActionsMenu.propTypes = {
  t: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
  projectModule: PropTypes.shape().isRequired,
  inclusion: PropTypes.shape().isRequired,
  moduleInstanceId: PropTypes.number.isRequired,
  placement: PropTypes.string.isRequired,
  disableTooltip: PropTypes.bool,
  deleteInstance: PropTypes.func.isRequired,
};

OtherActionsMenu.defaultProps = {
  disableTooltip: false,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  resyncProjectEntries: async () => dispatch(projectEntriesActions.resync({
    admin: ownProps.admin,
  })),
});

const mapStateToProps = (state) => ({
  projectElements: state.projectElements,
  elementLinks: state.elementLinks,
  elements: state.elements,
  user: state.auth.authUser,
  projectUsers: state.projectUsers,
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user.id === state.auth.authUser.id
  )),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ElementModuleModalView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    module: PropTypes.shape().isRequired,
    projectModule: PropTypes.shape().isRequired,
    inclusion: PropTypes.shape().isRequired,
    moduleInstanceId: PropTypes.number.isRequired,
    instanceName: PropTypes.string.isRequired,
    renameInstance: PropTypes.func.isRequired,
    project: PropTypes.shape().isRequired,
    tabName: PropTypes.string.isRequired,
    elements: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    projectUsers: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
    elementLinks: PropTypes.shape().isRequired,
    removeEntry: PropTypes.func.isRequired,
    resyncProjectEntries: PropTypes.func.isRequired,
    checkForCorruptedMissingDataCount: PropTypes.func.isRequired,
  };

  static getInitialState() {
    return {
      loading: false,
      contents: [],
      titleEditMode: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contents: [],
      titleEditMode: false,
    };
    this.elementRefs = {};
    this.dropdownRef = undefined;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectModule !== this.props.projectModule
      || prevProps.projectElements !== this.props.projectElements) this.load();
  }

  resetState = () => {
    this.setState(ElementModuleModalView.getInitialState(this.props));
  };

  show = () => {
    this.modal.show();
  };

  hide = () => {
    this.modal.hide();
  };

  isReadOnly = () => {
    const {
      projectUser, projectUsers, inclusion, project,
    } = this.props;
    return isInclusionReadOnly(inclusion, projectUser, Object.values(projectUsers), project);
  };

  load = async (loading = true) => {
    const {
      projectModule, elements, inclusion, project, elementLinks: elLinks, projectElements,
    } = this.props;
    const isReadOnly = this.isReadOnly();
    const elementLinks = Object.values(elLinks);
    const rootProjectElements = getRootProjectElements(projectElements, elementLinks);
    if (loading) this.setState({ loading: true });
    const getElement = (Type, pElement, element, ref = null) => {
      const { id } = pElement;
      return Type ? (
        <Type
          {...this.props}
          key={id}
          isEntryMode
          isEditMode={false}
          isReadOnly={isReadOnly}
          isAnonymized={inclusion.is_anonymized
              && element.identification_level === ELEMENT_DIRECT_IDENTIFIER}
          isModule
          elementId={pElement.element}
          inclusionId={inclusion ? inclusion.id : null}
          projectElementId={pElement.id}
          project={project}
          parent={projectModule}
          elementSortingHandlerId={getSortingHandlerId('element', id)}
          methods={{
            reload: this.load,
            checkLinks: () => checkChildrenLinks(
              elementLinks, id, this.elementRefs, rootProjectElements,
            ),
          }}
          ref={ref}
        />
      ) : null;
    };
    const contents = Object.values(projectElements).filter((pEl) => (
      pEl.module === projectModule.id
    )).sort(SortUtil.sortArray).map((pElement) => {
      const { id, element: elementId } = pElement;
      const element = elements[elementId];
      const Type = ElementUtil.getElementType(element);
      const elementType = element.type;
      const refClbk = (ref) => {
        if (elementType !== ELEMENT_TYPE_SPACER
        && elementType !== ELEMENT_TYPE_DIVIDER) this.elementRefs[id] = ref;
      };
      return getElement(Type, pElement, element, refClbk);
    });
    await new Promise((resolve) => this.setState({
      contents,
      loading: false,
    }, () => resolve()));
  };

  enableTitleEditMode = () => {
    this.titleEditValue = this.props.instanceName;
    this.setState({ titleEditMode: true });
  };

  cancelTitleChanges = () => {
    this.setState({ titleEditMode: false });
  };

  validateTitleChanges = () => {
    const value = this.titleEditValue;
    if (!value || value === '' || /^\s+$/.test(value)) {
      Toast.error(this.props, 'error:error.incorrect-module-title');
      return;
    }
    if (value.length > 30) {
      Toast.error(this.props, 'error:error.too-long-module-title');
      return;
    }
    if (value !== this.props.instanceName) {
      this.props.renameInstance(value);
    }
    this.setState({ titleEditMode: false });
  };

  deleteInstance = async () => {
    try {
      const {
        removeEntry, moduleInstanceId, resyncProjectEntries, checkForCorruptedMissingDataCount,
      } = this.props;
      await removeEntry(moduleInstanceId);
      await resyncProjectEntries();
      Toast.success(this.props, 'error:valid.success');
      checkForCorruptedMissingDataCount();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };


  render() {
    const {
      t, module, project, inclusion, instanceName, projectModule, moduleInstanceId,
    } = this.props;
    const isReadOnly = this.isReadOnly();

    return (
      <NewModal
        trigger={this.props.children}
        size="lg"
        type={1}
        disableHeaderTooltip
        xlHeader={`${ElementUtil.formatElementName(module, t)} — ${instanceName}`}
        onLoad={this.load}
        onClosed={this.resetState}
        extraClass="modal-white entry-modal"
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        <MixedView>
          <div className="row justify-content-center mb-3">
            <div className="col-auto">
              <h3 className="mb-1">
                {
                  this.state.titleEditMode ? (
                    <span>
                      <span
                        className="px-2 py-1"
                      >
                        <ElementContentEditable
                          defaultValue={instanceName}
                          onChange={(e) => {
                            this.titleEditValue = e.target.value;
                          }}
                          className="instance-name-edit d-inline-block"
                          style={{ height: '35px', minWidth: '5px' }}
                        />
                      </span>
                      <span className="ml-1">
                        <button
                          type="button"
                          className="no-button-style text-gray"
                          onClick={this.validateTitleChanges}
                          onKeyPress={this.validateTitleChanges}
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'check']}
                            transform="shrink-1"
                          />
                        </button>
                        <button
                          type="button"
                          className="no-button-style text-gray"
                          onClick={this.cancelTitleChanges}
                          onKeyPress={this.cancelTitleChanges}
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'ban']}
                            transform="shrink-4"
                          />
                        </button>
                      </span>
                    </span>
                  ) : (
                    <span>
                      <span className={`px-2 py-1${isReadOnly ? ' text-muted' : ''}`}>
                        {instanceName}
                      </span>
                      {module.can_rename_entry && (
                        <span className="ml-2">
                          <button
                            type="button"
                            className="no-button-style text-gray module-modal-edition-button"
                            disabled={isReadOnly}
                            onClick={this.enableTitleEditMode}
                            onKeyPress={this.enableTitleEditMode}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'pen']}
                              transform="shrink-3"
                            />
                          </button>
                        </span>
                      )}
                    </span>
                  )
                }
                <OtherActionsMenu
                  t={t}
                  disableTooltip={!module || !projectModule}
                  placement="right-start"
                  project={project}
                  inclusion={inclusion}
                  moduleInstanceId={moduleInstanceId}
                  projectModule={projectModule}
                  deleteInstance={this.deleteInstance}
                />
              </h3>
            </div>
          </div>
        </MixedView>
        <MobileView>
          <div className="row justify-content-center">
            <div className="col-10">
              <h3 className="mb-1">
                {
                  this.state.titleEditMode ? (
                    <span>
                      <div className="col-auto align-items-center text-center">
                        <span
                          className="px-2 py-1"
                        >
                          <ElementContentEditable
                            defaultValue={instanceName}
                            onChange={(e) => {
                              this.titleEditValue = e.target.value;
                            }}
                            className="instance-name-edit d-inline-block"
                            style={{ height: '35px', width: '100px' }}
                          />
                        </span>
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-auto">
                          <button
                            type="button"
                            className="no-button-style text-gray"
                            onClick={this.validateTitleChanges}
                            onKeyPress={this.validateTitleChanges}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'check']}
                              transform="shrink-1"
                            />
                          </button>
                        </div>
                        <div className="col-auto">
                          <button
                            type="button"
                            className="no-button-style text-gray"
                            onClick={this.cancelTitleChanges}
                            onKeyPress={this.cancelTitleChanges}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'ban']}
                              transform="shrink-4"
                            />
                          </button>
                        </div>
                      </div>
                    </span>
                  ) : (
                    <span>
                      <div className="col-auto align-items-center text-center">
                        <span className="px-2 py-1">
                          {instanceName}
                        </span>
                      </div>
                      {module.can_rename_entry && (
                        <div className="col-auto align-items-center text-center mr-0">
                          <button
                            type="button"
                            className="no-button-style text-gray module-modal-edition-button"
                            onClick={this.enableTitleEditMode}
                            onKeyPress={this.enableTitleEditMode}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'pen']}
                              transform="shrink-3"
                            />
                          </button>
                        </div>
                      )}
                    </span>
                  )
                }
                <div className="col-auto align-items-center text-center">
                  <OtherActionsMenu
                    t={t}
                    disableTooltip={!module || !projectModule}
                    placement="right-start"
                    project={project}
                    inclusion={inclusion}
                    moduleInstanceId={moduleInstanceId}
                    projectModule={projectModule}
                    deleteInstance={this.deleteInstance}
                  />
                </div>
              </h3>
            </div>
          </div>
        </MobileView>
        <div className={`module-entry-form-box${this.isReadOnly() ? ' read-only-bg' : ''}`}>
          <div className="element-tab module-entry-form entry-context px-1">
            {this.state.contents}
          </div>
        </div>
        {
          this.state.loading ? (
            <CardLoader />
          ) : null
        }
      </NewModal>
    );
  }
}


export default ElementModuleModalView;
