import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { childrenPropTypes } from '../utils/generic-prop-types';

const portal = document.createElement('div');
portal.classList.add('drag-and-drop-portal');
if (!document.body) {
  throw new Error('body not ready for portal creation!');
}
document.body.appendChild(portal);


const mapStateToProps = (state) => ({
  dragAndDrop: state.dragAndDrop,
});


@connect(mapStateToProps)
class DraggableComponent extends Component {
  static propTypes = {
    draggableId: PropTypes.string.isRequired,
    droppableId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    children: childrenPropTypes().isRequired,
    isDragDisabled: PropTypes.bool,
    usePortal: PropTypes.bool, // Usefull for modals,
    className: PropTypes.string,
  };

  static defaultProps = {
    isDragDisabled: false,
    usePortal: false,
    className: '',
  };

  render() {
    const {
      draggableId, index, isDragDisabled, children, usePortal, className,
    } = this.props;
    return (
      <Draggable
        key={draggableId}
        draggableId={draggableId}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => {
          const child = (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={className}
            >
              {children}
              {provided.placeholder}
            </div>
          );

          if (usePortal && snapshot.isDragging) {
            return ReactDOM.createPortal(child, portal);
          }
          return child;
        }}
      </Draggable>
    );
  }
}


export default DraggableComponent;
