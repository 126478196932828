import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import '../assets/css/authentication.css';
import authPicture from '../assets/img/auth_picture.svg';
import logo from '../assets/img/light_logo_r.png';
import { languages, nsOptions } from '../i18n';
import AccountValidated from './AccountValidated';
import Login from './Login';
import PasswordReset from './PasswordReset';
import PleaseConfirmEmail from './PleaseConfirmEmail';
import Recover from './Recover';
import Register from './Register';
import Validate from './Validate';
import Page from '../components/Page';
import { isTabletView } from '../components/TabletView';
import { isMobileView } from '../components/MobileView';
import { setLanguage } from '../utils/language';


@withTranslation('', nsOptions)
class AuthLayout extends Component {
  static propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
  };

  static getLogoWidth() {
    if (isTabletView()) return '60%';
    if (isMobileView()) return '60%';
    return '50%';
  }

  componentDidMount() {
    document.getElementById('body').className = 'authentication-body';
  }

  renderLanguageSelect = () => {
    const { i18n } = this.props;
    return (
      <select
        className="border-0 ml-2"
        value={i18n.language}
        onChange={(e) => setLanguage(i18n, e.target.value)}
      >
        {[...languages].reverse().map((language) => (
          <option
            key={language}
            value={language}
          >
            {language === 'fr' && 'Français'}
            {language === 'en' && 'English'}
          </option>
        ))}
      </select>
    );
  }

  render() {
    const { match } = this.props;
    const { url } = match;
    return (
      // Authorization checks are done in the nested Page components
      <Page {...this.props} checkAuthorizations={[]}>
        <div className="authentication">
          <div className="row">
            <div className="col-12 col-md-6 authentication-content-col d-flex flex-column">
              <div className="authentication-content-container py-2 py-sm-2 py-md-3 py-lg-4 py-xl-5">
                <div className="authentication-logo-container text-center">
                  <a href="/" rel="noopener noreferrer">
                    <img src={logo} width={AuthLayout.getLogoWidth()} alt="" />
                  </a>
                </div>
                <Route
                  {...this.props}
                  path={`${url}/register`}
                  component={Register}
                />
                <Route
                  {...this.props}
                  path={`${url}/login`}
                  component={Login}
                />
                <Route
                  {...this.props}
                  path={`${url}/recover`}
                  component={Recover}
                />
                <Route
                  {...this.props}
                  path={`${url}/please-confirm`}
                  component={PleaseConfirmEmail}
                />
                <Route
                  {...this.props}
                  path={`${url}/account-validated`}
                  component={AccountValidated}
                />
                <Route
                  {...this.props}
                  path={`${url}/password-reset/:token`}
                  component={PasswordReset}
                />
                <Route
                  {...this.props}
                  exact
                  path={`${url}/validate/:token`}
                  component={Validate}
                />
                <div className="col-12">
                  <div className="authentication-small-info">
                    {this.renderLanguageSelect()}
                  </div>
                </div>
                <div className="col-12">
                  <div className="authentication-small-info">
                    {`${process.env.REACT_APP_VERSION || 'dev'}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 d-none d-lg-block p-0">
              <div
                className="authentication-picture"
                style={{ backgroundImage: `url("${authPicture}")` }}
              />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}


export default AuthLayout;
