import Toast from './Toast';
import { isObject } from './object-util';

export const errorCodes = {
  license: 'license__.*',
  entryUniqueConstraintViolation: 'data__entry_unique_constraint_violation',
  dataDisclosureRisk: 'data__disclosure_risk',
};

export function baseMatchOneErrorOverride(errorCode) {
  const res = Object.values(errorCodes).find((exp) => new RegExp(`^${exp}$`).test(errorCode));
  return res;
}

/**
 * Handle api messages that we completly override
 * @param {Object} props
 * @param {Object} error
 */
export function handleApiErrorOverrides(props, error, showError = () => {}) {
  const matchError = (errorKey) => {
    const exp = errorCodes[errorKey];
    return exp ? new RegExp(`^__${exp}$`).test(error.message) : false;
  };

  if (matchError('license')) {
    const { getLicMsgModalManager } = props;
    if (getLicMsgModalManager) {
      if (error.message === '__license__max_concomitent_active_project_creations_reached') {
        getLicMsgModalManager({
          licMsgTitle: 'error:license.nb-concomitent-projects-reached.title',
          licMsgText: 'error:license.nb-concomitent-projects-reached.text',
          licMsgCancelLabel: 'common:button.close',
        }).show();
      } else {
        getLicMsgModalManager().show();
      }
    } else {
      // Should not append
      showError('error:license.need-upgrade');
      console.warn('Please wrap the calling component with withLicenseMsgModal HOC.');
    }
  } else if (matchError('entryUniqueConstraintViolation')) {
    showError('error:data.entry-unique-constraint');
  } else if (matchError('dataDisclosureRisk')) {
    const { getDiscMsgModalManager } = props;
    if (getDiscMsgModalManager) {
      getDiscMsgModalManager().show();
    } else {
      // Should not append
      showError('error:warning.data-disclosure-risk-3');
      console.warn('Please wrap the calling component with withDataDisclosureMsgModal HOC.');
    }
  } else {
    return false;
  }

  return true;
}

export async function baseHandlePageNotFound(error, currentPage, action) {
  if (error.response.status === 404 && currentPage !== 1) {
    try {
      await action();
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
}

export function baseMakeApiErrorUserFriendly(t, jsonError) {
  try {
    const errorData = JSON.parse(jsonError);
    if (!isObject(errorData)) return errorData;

    let msg = '';
    const breakIfNeeded = () => `${msg ? '\n' : ''}`;

    Object.keys(errorData).forEach((key) => {
      if (key === 'detail' || !Array.isArray(errorData[key])) {
        msg += `${breakIfNeeded()}${errorData[key].message || errorData[key]}`;
      } else {
        errorData[key].forEach((error) => {
          if (key !== 'non_field_errors') {
            msg += `${breakIfNeeded()}${t('error:on-field')} "${key}": `;
          }
          msg += error.message || error;
        });
      }
    });

    return msg;
  } catch (error) {
    return '';
  }
}

export function baseHandleCatched(props, error, notifyUser = true, showError = () => {}) {
  console.error(error);
  if (notifyUser) {
    if (error && /^error./.test(error.message)) {
      showError(`error:${error.message}`);
    } else if (handleApiErrorOverrides(props, error, showError)) {
      // Nothing to do (already donne in handleApiErrorOverrides function)
    } else if (error && error.message) {
      const { message } = error;
      const userFriendlyMsg = baseMakeApiErrorUserFriendly(props.t, message) || message;
      showError(userFriendlyMsg, null, true);
    } else {
      showError('error:error.internal-error');
    }
  }
}

export default class ErrorUtil {
  /**
   * Helper function to handle messages for catched errors
   * @param {Object} props
   * @param {Object} error
   * @param {Boolean} notifyUser
   */
  static handleCatched(props, error, notifyUser) {
    baseHandleCatched(
      props,
      error,
      notifyUser,
      (text, overrideTimeout, noT) => Toast.error(props, text, overrideTimeout, noT),
    );
  }

  /**
   * Check if the message will be ovveriden
   * @param {any} errorCode
   */
  static matchOneErrorOverride(errorCode) {
    return baseMatchOneErrorOverride(errorCode);
  }

  /**
   * Helper function to handle page not found error
   * @param {Object} error
   * @param {String} currentPage
   * @param {function} action
   */
  static async handlePageNotFound(error, currentPage, action) {
    return baseHandlePageNotFound(error, currentPage, action);
  }

  /**
   * Make api raw error messages more user friendly
   * @param {Object} props
   * @param {JSON} jsonError
   */
  static makeApiErrorUserFriendly(props, jsonError) {
    return baseMakeApiErrorUserFriendly(props.t, jsonError);
  }
}
